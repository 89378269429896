import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

export interface DrawerProps {
  open: boolean;
  onOpen?: () => void;
  onClose: () => void;
  actionButton?: ReactNode;
  sx?: SxProps;
  children?: ReactNode;
}

export default forwardRef(function Drawer(
  { open, onOpen = () => {}, onClose, actionButton, sx, children }: DrawerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      ModalProps={{ keepMounted: false }}
      disableSwipeToOpen
      disableScrollLock
    >
      <Box
        position="sticky"
        display="flex"
        justifyContent="space-between"
        top={0}
        zIndex={1000}
        p={1.5}
        sx={sx}
      >
        <IconButton onClick={onClose} size="large">
          <ArrowBackIcon />
        </IconButton>
        {actionButton}
      </Box>
      <Box
        ref={ref}
        role="presentation"
        width="100vw"
        height="100%"
        overflow="auto"
      >
        {children}
      </Box>
    </SwipeableDrawer>
  );
});
