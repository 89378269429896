import StarIcon from '@mui/icons-material/StarBorderOutlined';
import {
  Avatar,
  Box,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { Spinner } from '../../components/Spinner';
import { useAuth } from '../../contexts/authContext';
import { useTheme } from '../../contexts/themeContext';
import { LeaderboardUser, getLeaderboardUsers } from '../../firebase/api';
import capsatLogo from '../../icons/capsat.png';
import DinoCapsMedalIcon from '../../icons/DinoCapsMedalIcon';
import MedalIcon from '../../icons/MedalIcon';
import { getRatingType } from '../ProfilePage/ProfilePage';

const styles = {
  tabs: {
    width: '100%',

    '& > div': {
      borderBottom: 1,
      borderColor: 'divider',
    },

    '& .MuiTab-root': {
      minWidth: 0,
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: 'none',
    },
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 300px)',
  },
  tableRow: {
    '&:last-child td': {
      borderBottom: 0,
    },
  },
  selfTableRow: {
    position: 'sticky',
    bottom: 0,
    top: 31,
    backgroundColor: 'background.paper',
    zIndex: 1,
    borderRadius: 1,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',

    '& td': {
      borderBottom: 0,
    },
  },
  hideBorderBelow: {
    '& td': {
      borderBottom: 0,
    },
  },
  borderLeftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  top3: {
    borderRadius: 1,
    paddingRight: 0,
    paddingY: 1,
  },
  gold: {
    backgroundColor: '#F7C631',
    border: '2px solid #E3AA24',
    color: 'black',
  },
  silver: {
    backgroundColor: '#D1CCC5',
    border: '2px solid #B3ACA8',
    color: 'black',
  },
  bronze: {
    backgroundColor: '#CE834A',
    border: '2px solid #B37314',
    color: 'black',
  },
  twoLineTypography: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineHeight: 1.4,
  },
};

export const LeaderboardPage = () => {
  const { user: self } = useAuth();
  const navigate = useNavigate();
  const { darkModeEnabled } = useTheme();
  const [users, setUsers] = useState<LeaderboardUser[] | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState(0);

  const ratingType = getRatingType(currentTab);
  const ratingField = ('rating' + (ratingType ?? '')) as
    | 'rating'
    | 'rating7'
    | 'rating9'
    | 'rating11'
    | 'rating13';
  const hasMatchField = `hasMatch${ratingType ?? ''}` as
    | 'hasMatch'
    | 'hasMatch7'
    | 'hasMatch9'
    | 'hasMatch11'
    | 'hasMatch13';

  const leaderboardUsers = users
    ?.filter(user => user[hasMatchField])
    .toSorted((a, b) => b[ratingField] - a[ratingField]);

  useEffect(() => {
    const fetchUsersWithMatches = async () => {
      const fetchedUsers = await getLeaderboardUsers();
      setUsers(fetchedUsers);
    };

    fetchUsersWithMatches();
  }, [ratingType]);

  const loading = leaderboardUsers !== undefined;
  const userIndex = leaderboardUsers?.findIndex(user => user.id === self?.id);

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} py={2}>
        <Heading>Topplista</Heading>

        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          aria-label="tabs"
          sx={styles.tabs}
        >
          <Tab value={0} icon={<StarIcon />} aria-label="main rating" />
          <Tab value={1} label="7" />
          <Tab value={2} label="9" />
          <Tab value={3} label="11" />
          <Tab value={4} label="13" />
        </Tabs>

        {loading ? (
          <>
            <TableContainer sx={styles.tableContainer}>
              <Table
                size="small"
                aria-label="leaderboard"
                stickyHeader
                sx={{ px: 1 }}
              >
                <TableHead>
                  <TableRow
                    sx={{ '& > th': { bgcolor: 'background.default' } }}
                  >
                    <TableCell />
                    <TableCell align="left" sx={{ px: 0, pt: 0 }}>
                      Capsare
                    </TableCell>
                    <TableCell />
                    <TableCell sx={{ pt: 0, pl: 0, pr: 1.6 }}>Rating</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {leaderboardUsers.map((user, index) => {
                    const isSelf = index === userIndex;
                    const isBelowSelf = index - 1 === userIndex;
                    const isTop3 = index < 3;
                    const userAbove = leaderboardUsers.at(index - 1);
                    const userBelow = leaderboardUsers.at(index + 1);
                    const userRating = user[ratingField];
                    const isRatingSameAsAbove =
                      userRating === userAbove?.[ratingField];
                    const isRatingSameAsBelow =
                      userRating === userBelow?.[ratingField];
                    const hideFields = isRatingSameAsAbove && !isSelf;

                    return (
                      <TableRow
                        key={user.nameLabel}
                        onClick={() =>
                          navigate(isSelf ? '/profile' : `/user/${user.id}`, {
                            state: { prevPathname: '/leaderboard' },
                          })
                        }
                        sx={[
                          styles.tableRow,
                          isSelf && styles.selfTableRow,
                          isRatingSameAsBelow && styles.hideBorderBelow,
                          isBelowSelf &&
                            !isRatingSameAsAbove && {
                              '& td': {
                                borderTop: `1px solid ${
                                  darkModeEnabled
                                    ? 'rgba(81, 81, 81, 1)'
                                    : 'rgba(224, 224, 224, 1)'
                                }`,
                              },
                            },
                        ]}
                      >
                        <TableCell
                          align="center"
                          sx={[
                            { pl: 0.6, pr: 1.6 },
                            isSelf && styles.borderLeftRadius,
                          ]}
                        >
                          {!hideFields && (
                            <Box
                              sx={[
                                { width: 40 },
                                isTop3 && styles.top3,
                                index === 0 && styles.gold,
                                index === 1 && styles.silver,
                                index === 2 && styles.bronze,
                              ]}
                            >
                              #{index + 1}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell sx={{ pl: 0 }}>
                          {user.profilePicture !== undefined ? (
                            <Avatar
                              src={user.profilePicture}
                              variant="rounded"
                            />
                          ) : (
                            <Avatar
                              src={capsatLogo}
                              variant="rounded"
                              sx={{ filter: 'invert(0.5)' }}
                            />
                          )}
                        </TableCell>
                        <TableCell sx={{ pl: 0 }}>
                          <Box display="flex" alignItems="center">
                            {user.numberOfGlassesMedal !== undefined && (
                              <MedalIcon>{user.numberOfGlassesMedal}</MedalIcon>
                            )}
                            {user?.dinoCapsMedal === true && (
                              <DinoCapsMedalIcon />
                            )}

                            <Box
                              ml={
                                user.numberOfGlassesMedal !== undefined ||
                                user?.dinoCapsMedal === true
                                  ? 0.5
                                  : 0
                              }
                            >
                              <Typography variant="body2">
                                {user.nameLabel}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={styles.twoLineTypography}
                              >
                                {user.associationLabel}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={[
                            { pl: 0, pr: 1.6 },
                            isSelf && styles.borderRightRadius,
                          ]}
                        >
                          {!hideFields && (
                            <Typography variant="body2">
                              {userRating}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Container>
  );
};
