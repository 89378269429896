import { Box, Container, Stack, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  ExtendedPlayer,
  isEliminated,
  Player,
} from '../../components/PlayerCircle/PlayerCircle';
import TeamCircle, {
  Team,
  TeamColor,
} from '../../components/PlayerCircle/TeamCircle';
import { PointSystem } from '../../components/PointSystemToggle';
import JudgeCrossInstructions from './JudgeCrossInstructions';
import TeamStandings from './TeamStandings';

export interface JudgeCrossState {
  pointSystem: PointSystem;
  maxPoints: number;
  teams: readonly Team[];
}

export interface JudgeCrossPageProps {
  currentState: JudgeCrossState;
  setCurrentState: (newState: JudgeCrossState) => void;
}

export default function JudgeCirclePage({
  currentState,
  setCurrentState,
}: JudgeCrossPageProps) {
  const { pointSystem, maxPoints, teams } = currentState;
  const [maxPointsInput, setMaxPointsInput] = useState(maxPoints.toString());
  const isLivesPointSystem = pointSystem === 'lives';

  const players: readonly ExtendedPlayer[] = useMemo(() => {
    return teams
      .flatMap(team =>
        team.players.map(player => ({
          ...player,
          team: {
            ...team,
            color: (team.index === 0
              ? 'blue'
              : team.index === 1
                ? 'orange'
                : undefined) as TeamColor,
            points: team.players.reduce(
              (acc, player) => acc + player.points,
              isLivesPointSystem ? maxPoints : 0,
            ),
            warnings: team.players.reduce(
              (acc, player) => acc + player.warnings,
              0,
            ),
            shouldSwap: false,
          },
        })),
      )
      .toSorted((a, b) => a.index - b.index);
  }, [isLivesPointSystem, maxPoints, teams]);

  console.log({ players, teams });

  const winningTeam = useMemo(() => {
    const nonEliminatedTeams = teams.filter(team => {
      const teamPoints = team.players.reduce(
        (acc, player) => acc + player.points,
        isLivesPointSystem ? maxPoints : 0,
      );

      return !isEliminated(isLivesPointSystem, teamPoints, maxPoints);
    });

    return nonEliminatedTeams.length === 1 ? nonEliminatedTeams[0] : undefined;
  }, [isLivesPointSystem, maxPoints, teams]);

  const setTeams = (teams: readonly Team[]) =>
    setCurrentState({ ...currentState, teams });

  const setPlayers = (newPlayers: readonly Player[]) => {
    setTeams(
      teams.map(team => ({
        ...team,
        players: team.players.map(
          player =>
            newPlayers.find(newPlayer => newPlayer.id === player.id) ?? player,
        ),
      })),
    );
  };

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} mb={4}>
        <JudgeCrossInstructions isLivesPointSystem={isLivesPointSystem} />
        <TeamStandings
          teams={teams}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
          winningTeam={winningTeam}
        />
        <TeamCircle
          players={players}
          setPlayers={setPlayers}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />

        <Box textAlign="center" mt={2}>
          <Typography>
            {isLivesPointSystem ? 'Antal liv' : 'Antal poäng för att åka ut'}
          </Typography>
          <TextField
            type="number"
            value={maxPointsInput}
            onChange={event => {
              setMaxPointsInput(event.target.value);

              const newMaxPoints = parseInt(event.target.value);

              if (isNaN(newMaxPoints) || newMaxPoints <= 0) return;

              setCurrentState({ ...currentState, maxPoints: newMaxPoints });
            }}
            onBlur={() => {
              const newMaxPoints = parseInt(maxPointsInput);

              if (isNaN(newMaxPoints) || newMaxPoints <= 0) {
                setMaxPointsInput(maxPoints.toString());
              }
            }}
            variant="outlined"
            size="small"
            sx={{ width: 60, '& input': { textAlign: 'center' } }}
          />
        </Box>
      </Stack>
    </Container>
  );
}
