import { Player } from '../../components/PlayerCircle/PlayerCircle';

export function getTeamPoints(
  players: readonly Player[],
  isLivesPointSystem: boolean,
  maxPoints: number,
) {
  return players.reduce(
    (points, player) => points + player.points,
    isLivesPointSystem ? maxPoints : 0,
  );
}

export function getTeamWarnings(players: readonly Player[]) {
  return players.reduce((warnings, player) => warnings + player.warnings, 0);
}
