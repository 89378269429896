import WinnerIcon from '@mui/icons-material/EmojiEvents';
import JudgeIcon from '@mui/icons-material/Gavel';
import LoserIcon from '@mui/icons-material/HorizontalRule';
import {
  Avatar,
  Box,
  Modal,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import Container from '@mui/material/Container';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getInitials } from '../../components/Header/Header';
import { Spinner } from '../../components/Spinner';
import { useAuth } from '../../contexts/authContext';
import RatingTypeProvider from '../../contexts/numberOfGlassesContext';
import {
  getRankedUserMatches,
  getUser,
  Match,
  MatchResponse,
  onUserMatchesSnapshot,
  User,
} from '../../firebase/api';
import DinoCapsMedalIcon from '../../icons/DinoCapsMedalIcon';
import MedalIcon from '../../icons/MedalIcon';
import { Biography } from '../ProfilePage/Biography';
import { MatchList } from '../ProfilePage/MatchList';
import { getRatingType } from '../ProfilePage/ProfilePage';

export const NO_DATA_LABEL = '—';

const styles = {
  avatar: {
    width: 110,
    height: 110,
    border: '4px solid',
    borderColor: 'background.accent',
    ml: -0.375,
  },
  profilePictureModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tabs: {
    width: '100%',

    '& > div': {
      marginX: 2,
      borderBottom: 1,
      borderColor: 'divider',
    },

    '& .MuiTab-root': {
      minWidth: 0,
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: 'none',
    },
  },
  numberCircle: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: 'primary.contrastText',
    backgroundColor: (theme: Theme) => theme.palette.action.active,
  },
  numberCircleRating: {
    position: 'absolute',
    color: 'text.secondary',
    top: 22,
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

export interface UserPageProps {
  user: User;
}

function UserPage({ user }: UserPageProps) {
  const [userMatches, setUserMatches] = useState<Match[] | undefined>(
    undefined,
  );
  const [rankedUserMatches, setRankedUserMatches] = useState<
    MatchResponse[] | undefined
  >(undefined);
  const [currentTab, setCurrentTab] = useState(0);
  const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] =
    useState(false);

  const confirmedMatches = useMemo(() => {
    return userMatches?.filter(match => match.status === 'confirmed');
  }, [userMatches]);

  const unconfirmedWinnerOrLoserMatches = useMemo(() => {
    return userMatches?.filter(
      match =>
        match.status === 'pending' &&
        (match.winner.id === user.id || match.loser.id === user.id),
    );
  }, [user.id, userMatches]);

  const [hasMatch, hasMatch7, hasMatch9, hasMatch11, hasMatch13] =
    useMemo(() => {
      let hasMatch = false;
      let hasMatch7 = false;
      let hasMatch9 = false;
      let hasMatch11 = false;
      let hasMatch13 = false;

      for (const { numberOfGlasses } of rankedUserMatches ?? []) {
        hasMatch = true;

        if (numberOfGlasses === 7) {
          hasMatch7 = true;
        } else if (numberOfGlasses === 9) {
          hasMatch9 = true;
        } else if (numberOfGlasses === 11) {
          hasMatch11 = true;
        } else if (numberOfGlasses === 13) {
          hasMatch13 = true;
        }

        if (hasMatch7 && hasMatch9 && hasMatch11 && hasMatch13) break;
      }

      return [hasMatch, hasMatch7, hasMatch9, hasMatch11, hasMatch13];
    }, [rankedUserMatches]);

  const hasUnconfirmedMatches =
    unconfirmedWinnerOrLoserMatches !== undefined &&
    unconfirmedWinnerOrLoserMatches.length > 0;

  const unconfirmedRatingDelta =
    Math.round(user.unconfirmedRating) - Math.round(user.rating);

  const ratingType = getRatingType(currentTab);
  const matchList =
    ratingType !== undefined
      ? userMatches?.filter(match => match.numberOfGlasses === ratingType)
      : userMatches;

  const wins = confirmedMatches?.filter(
    match => match.winner.id === user.id,
  ).length;
  const losses = confirmedMatches?.filter(
    match => match.loser.id === user.id,
  ).length;
  const judgings = confirmedMatches?.filter(
    match => match.judge?.id === user.id,
  ).length;

  const matches =
    wins !== undefined && losses !== undefined ? wins + losses : undefined;
  const winRatio =
    wins !== undefined && matches !== undefined
      ? (matches > 0 ? ((wins / matches) * 100).toFixed() : 0) + '%'
      : undefined;
  const lossRatio =
    losses !== undefined && matches !== undefined
      ? (matches > 0 ? ((losses / matches) * 100).toFixed() : 0) + '%'
      : undefined;

  const loading = matchList === undefined;

  useEffect(() => {
    const fetchUserMatches = async () => {
      const unsubscribe = onUserMatchesSnapshot(user.id, setUserMatches);
      return unsubscribe;
    };

    fetchUserMatches();
  }, [user.id]);

  useEffect(() => {
    const fetchRankedUserMatches = async () => {
      const fetchedRankedUserMatches = await getRankedUserMatches(user.id);
      setRankedUserMatches(fetchedRankedUserMatches);
    };

    fetchRankedUserMatches();
  }, [user.id]);

  return (
    <Container component="main" maxWidth="sm">
      <Box mx={2}>
        <Box display="flex" justifyContent="space-between" gap={4}>
          <Box display="flex" mb={1.5}>
            {user?.profilePicture !== undefined ? (
              <>
                <Avatar
                  sx={styles.avatar}
                  src={user.profilePicture}
                  onClick={() => setIsProfilePictureModalOpen(true)}
                />
                <Modal
                  open={isProfilePictureModalOpen}
                  onClose={() => setIsProfilePictureModalOpen(false)}
                  aria-labelledby="profile-picture-modal"
                  aria-describedby="profile-picture-modal"
                >
                  <Box sx={styles.profilePictureModal}>
                    <Box
                      component="img"
                      src={user.profilePicture}
                      alt="Profile"
                      sx={{ width: '80vw', height: '100%', borderRadius: 2 }}
                    />
                  </Box>
                </Modal>
              </>
            ) : user !== null ? (
              <Avatar
                sx={[styles.avatar, { fontSize: 50, bgcolor: orange[700] }]}
              >
                {getInitials(user)}
              </Avatar>
            ) : (
              <Avatar />
            )}
          </Box>

          <Stack width="10.5rem">
            <Box display="flex" mt={3.5}>
              <Box display="flex" gap={0.5} alignItems="baseline" flex={6}>
                <Typography fontWeight={500}>
                  {matches ?? NO_DATA_LABEL}
                </Typography>
                <Typography variant="body2">
                  match{matches !== 1 ? 'er' : ''}
                </Typography>
              </Box>

              <Box display="flex" gap={0.5} alignItems="baseline" flex={4}>
                <Typography fontWeight={500}>
                  {hasMatch ? Math.round(user?.rating ?? 0) : NO_DATA_LABEL}
                </Typography>
                {hasUnconfirmedMatches && (
                  <Typography variant="body2" sx={{ opacity: 0.5, width: 0 }}>
                    ({unconfirmedRatingDelta >= 0 ? '+' : ''}
                    {unconfirmedRatingDelta})
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              gap={3.5}
              mt={1.75}
            >
              <Stack alignItems="center" gap={0.5} position="relative">
                <Box sx={styles.numberCircle}>7</Box>
                <Typography variant="body2" sx={styles.numberCircleRating}>
                  {hasMatch7 ? Math.round(user?.rating7 ?? 0) : NO_DATA_LABEL}
                </Typography>
              </Stack>

              <Stack alignItems="center" gap={0.5} position="relative">
                <Box sx={styles.numberCircle}>9</Box>
                <Typography variant="body2" sx={styles.numberCircleRating}>
                  {hasMatch9 ? Math.round(user?.rating9 ?? 0) : NO_DATA_LABEL}
                </Typography>
              </Stack>

              <Stack alignItems="center" gap={0.5} position="relative">
                <Box sx={styles.numberCircle}>11</Box>
                <Typography variant="body2" sx={styles.numberCircleRating}>
                  {hasMatch11 ? Math.round(user?.rating11 ?? 0) : NO_DATA_LABEL}
                </Typography>
              </Stack>

              <Stack alignItems="center" gap={0.5} position="relative">
                <Box sx={styles.numberCircle}>13</Box>
                <Typography variant="body2" sx={styles.numberCircleRating}>
                  {hasMatch13 ? Math.round(user?.rating13 ?? 0) : NO_DATA_LABEL}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>

        <Box display="flex" gap={2}>
          {user?.numberOfGlassesMedal !== undefined && (
            <MedalIcon>{user.numberOfGlassesMedal}</MedalIcon>
          )}

          {user?.dinoCapsMedal === true && <DinoCapsMedalIcon />}

          <div>
            <Typography fontSize={24} lineHeight={1.2} mt={0.75}>
              {user?.nameLabel ?? 'Anonym'}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={0.5}>
              {user?.associationLabel}
            </Typography>
          </div>
        </Box>

        {user?.biography && <Biography text={user.biography} />}
      </Box>

      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mt={2}
      >
        <Stack direction="row" gap={3} mt={2}>
          <Box display="flex" gap={0.25}>
            <Typography component="span">{wins}</Typography>
            <WinnerIcon color="success" />
          </Box>
          <Box display="flex" gap={0.25}>
            <Typography component="span">{losses}</Typography>
            <LoserIcon color="error" />
          </Box>
          <Box display="flex" gap={0.5}>
            <Typography component="span">{judgings}</Typography>
            <JudgeIcon color="action" />
          </Box>
        </Stack>

        <Box display="flex" mt={2}>
          <Typography>{winRatio}</Typography>
          <WinnerIcon color="success" />
          <Typography mx={0.5}>/</Typography>
          <Typography>{lossRatio}</Typography>
          <LoserIcon color="error" />
        </Box>

        <Typography variant="h5" mt={4}>
          Matcher
        </Typography>

        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          aria-label="tabs"
          sx={styles.tabs}
        >
          <Tab value={0} label="Alla" />
          <Tab value={1} label="7" />
          <Tab value={2} label="9" />
          <Tab value={3} label="11" />
          <Tab value={4} label="13" />
        </Tabs>

        {!loading ? (
          user !== null ? (
            matchList.length > 0 ? (
              <RatingTypeProvider ratingType={ratingType}>
                <MatchList matches={matchList} user={user} />
              </RatingTypeProvider>
            ) : (
              <Typography variant="body2" p={2}>
                Inga matcher än
              </Typography>
            )
          ) : null
        ) : (
          <Spinner />
        )}
      </Stack>
    </Container>
  );
}

export default function UserPageWrapper() {
  const { userId } = useParams();
  const { user: self } = useAuth();
  const [user, setUser] = useState<User | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId === undefined) return;

    if (userId === self?.id) {
      navigate('/profile');
      return;
    }

    const fetchUser = async () => {
      const fetchedUser = await getUser(userId);

      if (fetchedUser === null) {
        navigate('/');
        return;
      }

      setUser(fetchedUser);
    };

    fetchUser();
  }, [navigate, self?.id, userId]);

  if (user === undefined) return <Spinner />;

  return <UserPage key={user.id} user={user} />;
}
