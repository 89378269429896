import WinnerIcon from '@mui/icons-material/EmojiEvents';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import JudgeIcon from '@mui/icons-material/Gavel';
import UnrankedIcon from '@mui/icons-material/HandshakeOutlined';
import LoserIcon from '@mui/icons-material/HorizontalRule';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Link from '../../components/Link';
import { useAuth } from '../../contexts/authContext';
import { useRatingType } from '../../contexts/numberOfGlassesContext';
import { confirmMatch, Match, User } from '../../firebase/api';
import DinoCapsMedalIcon from '../../icons/DinoCapsMedalIcon';
import MedalIcon from '../../icons/MedalIcon';
import ConfirmRejectionDialog from './ConfirmRejectionDialog';

const styles = {
  link: {
    width: 'fit-content',
    margin: '0 auto',
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

interface InnerDialogProps {
  match: Match;
  user: User;
}

function InnerDialog({ match, user }: InnerDialogProps) {
  const { user: self } = useAuth();
  const ratingType = useRatingType();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    winner,
    loser,
    judge,
    submitter,
    numberOfGlasses,
    createdAt,
    medalChallenge = false,
    dinoCapsMedalChallenge = false,
    location,
    event,
    curiosity,
    unranked = false,
  } = match;
  const isWinner = user.id === winner.id;
  const isLoser = user.id === loser.id;
  const isSubmitter = user.id === submitter?.id;

  const date = new Date(createdAt).toLocaleDateString('sv-SE', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const title =
    user.id !== self?.id || isLoser
      ? `${winner.nameLabel} vann`
      : isWinner
        ? 'Du vann!'
        : 'Bra dömt!';

  let { winnerRating, loserRating, winnerRatingDelta, loserRatingDelta } =
    match;

  if (ratingType === 7) {
    winnerRating = match.winnerRating7;
    loserRating = match.loserRating7;
    winnerRatingDelta = match.winnerRating7Delta;
    loserRatingDelta = match.loserRating7Delta;
  } else if (ratingType === 9) {
    winnerRating = match.winnerRating9;
    loserRating = match.loserRating9;
    winnerRatingDelta = match.winnerRating9Delta;
    loserRatingDelta = match.loserRating9Delta;
  } else if (ratingType === 11) {
    winnerRating = match.winnerRating11;
    loserRating = match.loserRating11;
    winnerRatingDelta = match.winnerRating11Delta;
    loserRatingDelta = match.loserRating11Delta;
  } else if (ratingType === 13) {
    winnerRating = match.winnerRating13;
    loserRating = match.loserRating13;
    winnerRatingDelta = match.winnerRating13Delta;
    loserRatingDelta = match.loserRating13Delta;
  }

  const winnerRatingLabel = Math.round(winnerRating ?? 0);
  const loserRatingLabel = Math.round(loserRating ?? 0);
  const winnerRatingDeltaLabel = `+${Math.abs(Math.round(winnerRatingDelta ?? 0))}`;
  const loserRatingDeltaLabel = `-${Math.abs(Math.round(loserRatingDelta ?? 0))}`;

  const handleOpenConfirmDialog = () => setOpenConfirmationDialog(true);

  const handleCloseConfirmDialog = () => setOpenConfirmationDialog(false);

  const handleConfirmResultClick = async () => {
    if (match.status !== 'pending') return;

    setLoading(true);

    await confirmMatch({ ...match, confirmer: user });

    setLoading(false);
  };

  return (
    <>
      {match.status === 'pending' && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="text.secondary"
          mt={2}
        >
          <Typography variant="body2">Inväntar bekräftelse...</Typography>
        </Box>
      )}

      <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>

      <DialogContent>
        <Stack textAlign="center">
          <Typography>{numberOfGlasses} glas</Typography>

          <Box display="flex" justifyContent="space-between" gap={2}>
            <Stack flex={1} margin="0 auto">
              <WinnerIcon color="success" sx={{ mx: 'auto' }} />
              <Link to={`/user/${winner.id}`} sx={styles.link}>
                {winner.nameLabel}
              </Link>
              <Typography
                variant="body2"
                whiteSpace="normal"
                color="text.secondary"
                sx={{ wordBreak: 'break-word' }}
              >
                {winner.associationLabel}
              </Typography>
              <div>
                <Typography component="span" variant="body2">
                  {winnerRatingLabel}{' '}
                </Typography>
                {!unranked && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="success.main"
                  >
                    ({winnerRatingDeltaLabel})
                  </Typography>
                )}
              </div>

              <Box display="flex" justifyContent="center">
                {medalChallenge && (
                  <Box display="flex" justifyContent="center">
                    <MedalIcon>{numberOfGlasses}</MedalIcon>
                  </Box>
                )}
                {dinoCapsMedalChallenge && (
                  <Box display="flex" justifyContent="center">
                    <DinoCapsMedalIcon />
                  </Box>
                )}
              </Box>
            </Stack>

            <Typography flex={0} alignSelf="center">
              vs
            </Typography>

            <Stack flex={1} margin="0 auto">
              <LoserIcon color="error" sx={{ mx: 'auto' }} />
              <Link to={`/user/${loser.id}`} sx={styles.link}>
                {loser.nameLabel}
              </Link>
              <Typography
                variant="body2"
                whiteSpace="normal"
                color="text.secondary"
                sx={{ wordBreak: 'break-word' }}
              >
                {loser.associationLabel}
              </Typography>
              <div>
                <Typography component="span" variant="body2">
                  {loserRatingLabel}{' '}
                </Typography>
                {!unranked && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="error.main"
                  >
                    ({loserRatingDeltaLabel})
                  </Typography>
                )}
              </div>
            </Stack>
          </Box>

          {judge !== null && (
            <Stack>
              <JudgeIcon color="action" sx={{ mx: 'auto' }} />
              <Link to={`/user/${judge.id}`} sx={styles.link}>
                {judge.nameLabel}
              </Link>
              <Typography
                variant="body2"
                whiteSpace="normal"
                color="text.secondary"
                sx={{ wordBreak: 'break-word' }}
              >
                {judge.associationLabel}
              </Typography>
            </Stack>
          )}

          {(location !== undefined ||
            event !== undefined ||
            curiosity !== undefined ||
            unranked) && (
            <Box mt={2} textAlign="left">
              {location !== undefined && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <LocationOnOutlinedIcon fontSize="small" />
                  <Typography variant="body2">{location}</Typography>
                </Stack>
              )}

              {event !== undefined && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <EventOutlinedIcon fontSize="small" />
                  <Typography variant="body2">{event}</Typography>
                </Stack>
              )}

              {curiosity !== undefined && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <InfoOutlinedIcon fontSize="small" />
                  <Typography variant="body2">{curiosity}</Typography>
                </Stack>
              )}

              {unranked && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <UnrankedIcon fontSize="small" />
                  <Typography variant="body2">Orankat</Typography>
                </Stack>
              )}
            </Box>
          )}

          <Typography variant="caption" mt={2}>
            {date}
          </Typography>
        </Stack>
      </DialogContent>

      {match.status === 'pending' && user.id === self?.id && (
        <Stack spacing={2} mx="auto" mt={2} mb={3}>
          <Typography variant="body2" textAlign="center">
            Inskickad av {submitter?.nameLabel ?? 'anonym'}
          </Typography>
          <LoadingButton
            variant="contained"
            onClick={handleConfirmResultClick}
            loading={loading}
            disabled={isSubmitter || (isWinner && submitter === null)}
            autoFocus
          >
            Bekräfta resultat
          </LoadingButton>

          <Button
            onClick={handleOpenConfirmDialog}
            color="inherit"
            disabled={isLoser && !isSubmitter}
            size="small"
          >
            Invalidera
          </Button>
          <ConfirmRejectionDialog
            open={openConfirmationDialog}
            onClose={handleCloseConfirmDialog}
            match={match}
          />
        </Stack>
      )}
    </>
  );
}

export interface MatchModalProps extends InnerDialogProps {
  open: boolean;
  onClose: () => void;
}

export function MatchModal({ open, onClose, ...props }: MatchModalProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <InnerDialog {...props} />
    </Dialog>
  );
}
