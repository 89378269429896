import SwapIcon from '@mui/icons-material/SwapHoriz';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { ExtendedTeam } from '../../../components/PlayerCircle/TeamCircle';
import { JudgeCrossState } from '../../JudgeCrossPage/JudgeCrossPage';

interface InnerDialogProps extends ConfirmSwapPlayersModalProps {
  onClose: () => void;
}

function InnerDialog({
  onClose,
  teamToSwap,
  currentState,
  setCurrentState,
}: InnerDialogProps) {
  const handleSwap = () => {
    setCurrentState({
      ...currentState,
      teams: currentState.teams.map(team => {
        if (team.id === teamToSwap.id) {
          const tempIndex = team.players[0].index;
          team.players[0].index = team.players[1].index;
          team.players[1].index = tempIndex;
        }

        return team;
      }),
    });

    onClose();
  };

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Byte för lag {teamToSwap.name}!
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="center" gap={1}>
          {teamToSwap.players.map((player, index) => (
            <Fragment key={player.id}>
              {index > 0 && <SwapIcon />}
              <Typography>{player.name}</Typography>
            </Fragment>
          ))}
        </Box>
      </DialogContent>

      <Stack mx={4} mb={3} gap={2}>
        <Button variant="contained" onClick={handleSwap} fullWidth>
          Byt plats
        </Button>
        <Button variant="outlined" onClick={onClose} fullWidth>
          Stäng
        </Button>
      </Stack>
    </>
  );
}

export interface ConfirmSwapPlayersModalProps {
  teamToSwap: ExtendedTeam;
  currentState: JudgeCrossState;
  setCurrentState: (newState: JudgeCrossState) => void;
}

export function ConfirmSwapPlayersModal(props: ConfirmSwapPlayersModalProps) {
  const [exited, setExited] = useState(false);

  const onClose = () => setExited(true);

  return (
    <Dialog
      open={!exited}
      onClose={onClose}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          position: 'absolute',
          bottom: 30,
        },
      }}
    >
      <InnerDialog onClose={onClose} {...props} />
    </Dialog>
  );
}
