import { Box, Stack, Typography } from '@mui/material';

import { useMemo } from 'react';
import { Player } from '../../components/PlayerCircle/PlayerCircle';
import { Team } from '../../components/PlayerCircle/TeamCircle';
import { getTeamPoints, getTeamWarnings } from '../ToolsPage/helpers';

export interface TeamStandingRowProps {
  id: string;
  index: number;
  name: string;
  players: readonly Player[];
  isLivesPointSystem: boolean;
  maxPoints: number;
  winningTeam: Team | undefined;
}

function TeamStandingRow({
  id,
  index,
  name,
  players,
  isLivesPointSystem,
  maxPoints,
  winningTeam,
}: TeamStandingRowProps) {
  const teamPoints = useMemo(() => {
    return getTeamPoints(players, isLivesPointSystem, maxPoints);
  }, [players, isLivesPointSystem, maxPoints]);

  const teamWarnings = useMemo(() => {
    return getTeamWarnings(players);
  }, [players]);

  const teamColor = useMemo(() => {
    if (index === 0) {
      return 'rgba(0, 153, 255, 0.8)';
    } else if (index === 1) {
      return 'rgba(255, 120, 0, 0.8)';
    } else {
      return 'gray';
    }
  }, [index]);

  const isWinningTeam = useMemo(() => {
    return winningTeam?.id === id;
  }, [winningTeam, id]);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Box
        display="flex"
        justifyContent="center"
        borderRadius={1}
        bgcolor={teamColor}
        p={1}
        mr={1}
        width="2rem"
        textAlign="center"
        color="white"
        alignItems="center"
      >
        {teamPoints}
      </Box>
      {isWinningTeam && (
        <Box fontSize={20} mt={-0.5} mr={-0.75}>
          🏆
        </Box>
      )}
      {teamWarnings > 0 && (
        <Box
          display="flex"
          alignItems="center"
          borderRadius={1}
          height={35}
          pl={1}
          pr={1.5}
        >
          {teamWarnings}x
          <Box fontSize={20} mt={-0.5} mr={-0.75}>
            ⚠️
          </Box>
        </Box>
      )}
      <Typography>{name}</Typography>
    </Stack>
  );
}

export interface TeamStandingsProps {
  teams: readonly Team[];
  isLivesPointSystem: boolean;
  maxPoints: number;
  winningTeam: Team | undefined;
}

export default function TeamStandings({
  teams,
  isLivesPointSystem,
  maxPoints,
  winningTeam,
}: TeamStandingsProps) {
  const sortedTeams = teams.toSorted((a, b) => a.index - b.index);

  return (
    <Stack gap={1}>
      {sortedTeams.map(({ id, index, name, players }) => (
        <TeamStandingRow
          key={id}
          id={id}
          index={index}
          name={name}
          players={players}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
          winningTeam={winningTeam}
        />
      ))}
    </Stack>
  );
}
