import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../components/Spinner';
import TextButton from '../../components/TextButton';
import { addUser } from '../../firebase/auth';

export interface SignUpFormProps {
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
}

export default function SignUpForm({
  email,
  password,
  setEmail,
  setPassword,
}: SignUpFormProps) {
  const navigate = useNavigate();
  const [names, setNames] = useState(['']);
  const [associations, setAssociations] = useState(['']);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handlePasswordVisibilityClick = () => setShowPassword(!showPassword);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) return;

    setLoading(true);

    try {
      await addUser(
        names.map(name => name.trim()),
        associations
          .map(association => association.trim())
          .filter(association => association !== ''),
        email,
        password,
      );
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
      navigate('/');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack sx={{ mt: 8, alignItems: 'center' }}>
        {!loading ? (
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
        ) : (
          <Spinner />
        )}

        <Typography component="h1" variant="h5">
          Skapa konto
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {errorMessage !== undefined && (
            <Alert severity="error" onClose={() => setErrorMessage(undefined)}>
              {errorMessage}
            </Alert>
          )}

          {names.map((name, index) => (
            <TextField
              key={index}
              id="displayName"
              name="displayName"
              label="Visningsnamn"
              margin="normal"
              value={name}
              required
              fullWidth
              onChange={event => {
                const newNames = [...names];
                newNames[index] = event.target.value;
                setNames(newNames);
              }}
              InputProps={{
                endAdornment:
                  names.length > 1 ? (
                    <InputAdornment position="end">
                      <CloseIcon
                        fontSize="small"
                        aria-label="Ta bort namn"
                        onClick={() => {
                          const newNames = [...names];
                          newNames.splice(index, 1);
                          setNames(newNames);
                        }}
                      />
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          ))}

          <Box display="flex" justifyContent="flex-end">
            <TextButton onClick={() => setNames([...names, ''])}>
              Lägg till +
            </TextButton>
          </Box>

          {associations.map((association, index) => (
            <TextField
              key={index}
              id="association"
              name="association"
              label="Förening"
              margin="normal"
              value={association}
              fullWidth
              onChange={event => {
                const newAssociations = [...associations];
                newAssociations[index] = event.target.value;
                setAssociations(newAssociations);
              }}
              InputProps={{
                endAdornment:
                  associations.length > 1 ? (
                    <InputAdornment position="end">
                      <CloseIcon
                        fontSize="small"
                        aria-label="Ta bort förening"
                        onClick={() => {
                          const newAssociations = [...associations];
                          newAssociations.splice(index, 1);
                          setAssociations(newAssociations);
                        }}
                      />
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          ))}

          <Box display="flex" justifyContent="flex-end">
            <TextButton onClick={() => setAssociations([...associations, ''])}>
              Lägg till +
            </TextButton>
          </Box>

          <TextField
            id="email"
            name="email"
            label="E-post"
            autoComplete="email"
            margin="normal"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
            fullWidth
          />

          <TextField
            id="password"
            name="password"
            label="Lösenord"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            margin="normal"
            value={password}
            onChange={event => setPassword(event.target.value)}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisibilityClick}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon titleAccess="Dölj lösenord" />
                    ) : (
                      <VisibilityIcon titleAccess="Visa lösenord" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Skapa konto
          </Button>

          <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2">
              Har du redan ett konto?{' '}
              <Link
                onClick={() => navigate('/login')}
                sx={{
                  cursor: 'pointer',
                  WebkitTapHighlightColor: 'transparent',
                }}
              >
                Logga in
              </Link>
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
