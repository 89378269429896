import SwapIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Player } from '../../../components/PlayerCircle/PlayerCircle';
import { TeamColor } from '../../../components/PlayerCircle/TeamCircle';
import { JudgeCrossState } from '../../JudgeCrossPage/JudgeCrossPage';

const styles = {
  circleWrapper: {
    position: 'relative',
    width: '100%',
    height: '70vw',
    borderRadius: '50%',
    marginX: 'auto',
  },
  circle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformStyle: 'preserve-3d',
  },
  playerName: {
    zIndex: 2,
    mt: '3.2rem',
    pointerEvents: 'none',
  },
  player: {
    alignItems: 'center',
    WebkitTapHighlightColor: 'transparent',
  },
  head: {
    height: '2rem',
    width: '2rem',
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    borderRadius: '2rem',
    backgroundColor: 'background.paper',
  },
  body: {
    height: '2.5rem',
    width: '4rem',
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    borderRadius: '2.5rem 2.5rem 0 0',
    backgroundColor: 'background.paper',
    justifyContent: 'center',
    alignItems: 'center',

    '& p': {
      marginTop: '0.2rem',
    },
  },
  isBlue: {
    '& div': {
      boxShadow:
        'inset 0 0 0.3rem rgba(0, 153, 255, 1), inset 0 0 0.2rem rgba(0, 153, 255, 0.8)',
    },
  },
  isOrange: {
    '& div': {
      boxShadow:
        'inset 0 0 0.3rem rgba(255, 120, 0, 1), inset 0 0 0.2rem rgba(255, 120, 0, 0.8)',
    },
  },
};

export interface PlayerPieceProps {
  color: TeamColor;
}

function PlayerPiece({ color }: PlayerPieceProps) {
  return (
    <Stack alignItems="center">
      <Stack
        sx={[
          styles.player,
          color === 'blue' && styles.isBlue,
          color === 'orange' && styles.isOrange,
        ]}
      >
        <Box sx={styles.head} />
        <Box sx={styles.body} />
      </Stack>
    </Stack>
  );
}

export interface TeamCirclePlayerProps {
  player: Player;
  color: TeamColor;
  angle: number;
}

function TeamCirclePlayer({ player, color, angle }: TeamCirclePlayerProps) {
  const { name } = player;
  const transform = `rotate(${angle}deg) translateX(min(30vw, 300px)) rotate(${-angle}deg)`;

  return (
    <>
      <Box sx={[styles.circle, { transform }]}>
        <PlayerPiece color={color} />
      </Box>

      <Stack sx={[styles.circle, styles.playerName, { transform }]}>
        <Typography
          variant="caption"
          textAlign="center"
          whiteSpace="nowrap"
          fontWeight={500}
          lineHeight={1}
        >
          {name}
        </Typography>
      </Stack>
    </>
  );
}

export interface SwapButtonProps {
  angle: number;
  player: Player;
  playerCount: number;
  setPlayers: Dispatch<SetStateAction<readonly Player[]>>;
}

function SwapButton({
  angle,
  player,
  playerCount,
  setPlayers,
}: SwapButtonProps) {
  const [swapRotation, setSwapRotation] = useState(0);

  const swapButtonAngle = angle + 180 / playerCount;
  const swapButtonTransform = `rotate(${swapButtonAngle}deg) translateX(min(22vw, 220px))`;

  const handleSwapClick = () => {
    setSwapRotation(prev => prev + 180);

    setPlayers(prevPlayers => {
      const index1 = player.index;
      const index2 = (index1 + 1) % playerCount;

      return prevPlayers
        .map(prevPlayer => ({
          ...prevPlayer,
          index:
            prevPlayer.index === index1
              ? index2
              : prevPlayer.index === index2
                ? index1
                : prevPlayer.index,
        }))
        .toSorted((a, b) => a.index - b.index);
    });
  };

  return (
    <Box sx={[styles.circle, { transform: swapButtonTransform }]}>
      <IconButton
        aria-label="swap"
        size="small"
        onClick={handleSwapClick}
        sx={{
          transform: `rotate(${swapRotation}deg)`,
          transition: 'all 0.2s linear',
        }}
      >
        <SwapIcon />
      </IconButton>
    </Box>
  );
}

interface InnerDialogProps {
  onClose: () => void;
  players: readonly Player[];
  currentState: JudgeCrossState;
  setCurrentState: (newState: JudgeCrossState) => void;
}

function InnerDialog({
  onClose,
  players: initialPlayers,
  currentState,
  setCurrentState,
}: InnerDialogProps) {
  const [players, setPlayers] = useState(initialPlayers);
  const playerCount = players.length;
  const { teams } = currentState;

  const handleSaveNewOrder = () => {
    const newState: JudgeCrossState = {
      ...currentState,
      teams: currentState.teams.map(team => ({
        ...team,
        players: team.players.map(
          player => players.find(({ id }) => id === player.id) ?? player,
        ),
      })),
    };

    setCurrentState(newState);
    onClose();
  };

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>Byt plats</DialogTitle>

      <DialogContent>
        <Box sx={styles.circleWrapper}>
          {players.map((player, index) => {
            const angle = 90 + (180 * (2 * index + 1)) / playerCount;
            const team = teams.find(team =>
              team.players.some(p => p.id === player.id),
            );
            const color =
              team?.index === 0
                ? 'blue'
                : team?.index === 1
                  ? 'orange'
                  : undefined;

            return (
              <TeamCirclePlayer
                key={player.id}
                player={player}
                color={color}
                angle={angle}
              />
            );
          })}
        </Box>

        <div>
          {initialPlayers.map((player, index) => {
            const angle = 90 + (180 * (2 * index + 1)) / playerCount;

            return (
              <SwapButton
                key={player.id}
                angle={angle}
                player={player}
                playerCount={playerCount}
                setPlayers={setPlayers}
              />
            );
          })}
        </div>
      </DialogContent>

      <Box mx={4} mb={3}>
        <Button variant="contained" onClick={handleSaveNewOrder} fullWidth>
          Spara
        </Button>
      </Box>
    </>
  );
}

export interface SwapPlayersModalProps extends InnerDialogProps {
  open: boolean;
}

export function SwapPlayersModal({ open, ...props }: SwapPlayersModalProps) {
  return (
    <Dialog open={open} onClose={props.onClose} fullWidth>
      <InnerDialog {...props} />
    </Dialog>
  );
}
